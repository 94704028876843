
import { gql } from 'graphql-tag'
import CMasthead from '~/components/organisms/c-masthead'
import CBackgroundImageCopy from '~/components/organisms/c-background-image-copy'
import CCtaBox from '~/components/organisms/c-cta-box'
import CImageCopyBlocks from '~/components/organisms/c-image-copy-blocks'
import template, { fragments } from '~/mixins/template'

export default {
  mixins: [template],
  async asyncData ({ error, app, route }) {
    let data = app.apolloProvider.defaultClient.query({
      query: gql`
        query($uri: [String]!, $site: [String]!) {
          entry(uri: $uri, site: $site) {
            ${fragments.seomatic}
            uri
            sectionHandle
            typeHandle
            title
            ... on homepage_homepage_Entry {
              masthead {
                ...masthead
              }
              backgroundImageCopy3 {
                ...backgroundImageCopy3
              }
              imageCopyBlocks {
                ...imageCopyBlocks
              }
              backgroundImageCopy {
                ...backgroundImageCopy
              }
              imageCopyBlocks2 {
                ...imageCopyBlocks2
              }
              backgroundImageCopy2 {
                ...backgroundImageCopy2
              }
              imageCopyBlocks3 {
                ...imageCopyBlocks3
              }
              ctaBox {
                ...ctaBox
              }
            }
          }
        }
        ${CMasthead.fragments.field}
        ${CBackgroundImageCopy.fragments.field}
        ${CBackgroundImageCopy.fragments.field2}
        ${CBackgroundImageCopy.fragments.field3}
        ${CCtaBox.fragments.field}
        ${CImageCopyBlocks.fragments.field}
        ${CImageCopyBlocks.fragments.field2}
        ${CImageCopyBlocks.fragments.field3}
      `,
      variables: {
        uri: '__home__',
        site: app.i18n.localeProperties.handle
      }
    })

    let global = app.$globals()

    ;[{ data }, global] = await Promise.all([data, global])

    if (!data?.entry) {
      return error({
        statusCode: 404
      })
    }

    return { ...data, global }
  },
  watch: {
    '$window.pageYOffset' () {
      const isClear = this.$window.pageYOffset < (window.innerWidth > 678 ? 280 : 200)
      if (this.$store.state.cTheHeader.isClear !== isClear) {
        this.$store.commit('cTheHeader/setIsClear', isClear)
      }
    }
  },
  mounted () {
    this.$store.commit('cTheHeader/setIsClear', true)
  },
  beforeDestroy () {
    this.$store.commit('cTheHeader/setIsClear', false)
  }
}
