import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0f992370&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CMasthead: require('/vercel/path0/components/organisms/c-masthead.vue').default,CKrTempImageList: require('/vercel/path0/components/organisms/c-kr-temp-image-list.vue').default,CBackgroundImageCopy: require('/vercel/path0/components/organisms/c-background-image-copy.vue').default,CImageCopyBlocks: require('/vercel/path0/components/organisms/c-image-copy-blocks.vue').default,CStats: require('/vercel/path0/components/organisms/c-stats.vue').default,CCtaBox: require('/vercel/path0/components/organisms/c-cta-box.vue').default,CFindADoctor: require('/vercel/path0/components/organisms/c-find-a-doctor.vue').default,CCenteredCopy: require('/vercel/path0/components/organisms/c-centered-copy.vue').default,CKrNewsletter: require('/vercel/path0/components/organisms/c-kr-newsletter.vue').default})
