import { clearAllBodyScrollLocks } from 'body-scroll-lock'

export const fragments = {
  seomatic: `
    seomatic(asArray: true) {
      metaTitleContainer
      metaTagContainer
      metaScriptContainer
      metaLinkContainer
      metaJsonLdContainer
    }
  `
}

export default {
  data () {
    return {
      entry: null
    }
  },
  created () {
    clearAllBodyScrollLocks()
  },
  mounted () {
    this.handleScroll()
  },
  head () {
    const headData = {
      bodyAttrs: {
        class: this.$i18n.localeProperties.code === 'ko-KR' ? 's-word-break-keep-all' : ''
      }
    }

    const seoData = this.category?.seomatic || this.entry?.seomatic

    if (seoData) {
      // Convert the graphql JSON data to an object so we can work with it
      const {
        metaTitleContainer: {
          title: { title }
        },
        metaTagContainer,
        metaLinkContainer,
        metaScriptContainer,
        metaJsonLdContainer
      } = Object.entries(seoData).reduce(
        (acc, [key, value]) => {
          if (key !== '__typename') {
            acc[key] = JSON.parse(value)
            return acc
          }
          return acc
        },
        {}
      )

      // Flatten metaTagContainer values into string
      const meta = metaTagContainer
        ? Object.values(metaTagContainer).reduce(
          (flat, next) => flat.concat(next),
          []
        )
        : null

      // Flatten metaLinkContainer values into string
      const link = metaLinkContainer
        ? Object.values(metaLinkContainer).reduce(
          (flat, next) => flat.concat(next),
          []
        )
        : null

      // Convert script data
      const metaScripts = metaScriptContainer
        ? Object.values(metaScriptContainer).map(({ script }) => ({
          innerHTML: script
        }))
        : []

      // Convert JsonLd
      const jsonLd = metaJsonLdContainer
        ? [{
            type: 'application/ld+json',
            innerHTML: JSON.stringify(Object.values(metaJsonLdContainer))
          }]
        : []

      // Combine processed script data
      const script = [...metaScripts, ...jsonLd]

      Object.assign(headData, {
        ...(title && { title }),
        ...(meta && { meta }),
        ...(link && { link }),
        script,
        __dangerouslyDisableSanitizers: ['script']
      })
    }

    return headData
  },
  methods: {
    handleScroll () {
      if (window.location.hash.startsWith('#')) {
        const targetHash = window.location.hash
        const targetEl = targetHash === '#'
          ? document.documentElement
          : document.getElementById(targetHash.replace(/^#/, ''))
        if (targetEl) {
          window.scrollTo({
            top: targetEl.getBoundingClientRect().top + window.pageYOffset -
              (parseInt(getComputedStyle(document.documentElement).getPropertyValue('--scroll-spacing-top'), 10) || 0),
            behavior: 'smooth'
          })
        }
      }
    }
  }
}
