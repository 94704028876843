
import throttle from 'lodash/throttle'

export default {
  data () {
    return {
      blocksActive: [false],
      blocksActiveDown: [false],
      handleWindowScrollThrottled: throttle(this.handleWindowScroll, 100)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleWindowScrollThrottled)
    this.handleWindowScroll()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleWindowScrollThrottled)
  },
  methods: {
    handleWindowScroll () {
      const element = this.$refs.block
      this.$set(
        this.blocksActive,
        0,
        (element.getBoundingClientRect().top <= window.innerHeight * 0.5) && (element.getBoundingClientRect().bottom >= window.innerHeight * 0.5)
      )
      this.$set(
        this.blocksActiveDown,
        0,
        (element.getBoundingClientRect().top <= window.innerHeight * 0.5)
      )
    }
  }
}
