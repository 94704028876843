
import { gql } from 'graphql-tag'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import Hls from 'hls.js'

export default {
  props: {
    field: { type: Array, default: null },
    lensBranding: { type: String, default: '' }
  },
  data () {
    return {
      isActive: true,
      blocksActive: [],
      blocksOpacity: [],
      backgroundCanvasContext: null,
      backgroundFrames: [],
      backgroundAnimation: {
        frame: 0
      },
      timeline: null
    }
  },
  computed: {
    content () {
      if (this.field && this.field.length) {
        let main = this.field.find(block => block.typeHandle === 'main')
        if (decodeURIComponent(this.$route.path) === this.$t('paths.harmony')) {
          const branding = this.lensBranding === 'visian-icl' ? 'icl' : 'evo'
          main = Object.assign({}, main, { backgroundFrames: [] })
          for (let frameIndex = 0; frameIndex <= 120; frameIndex++) {
            main.backgroundFrames.push({
              // Note that this URL is extensionless, can't check for webp support till mounted hook runs
              // Also, this code can run server-side, and async func dont below in a computede func
              url: `${process.env.backendUrl}frames/harmony/${branding}/${frameIndex.toString().padStart(4, '0')}`,
              title: 'Blinking Eye with Lens'
            })
          }
        }
        return {
          ...main,
          blocks: this.field.filter(block => block.typeHandle === 'block')
        }
      } else {
        return null
      }
    }
  },
  fragments: {
    field: gql`
      fragment masthead on masthead_MatrixField {
        ... on masthead_main_BlockType {
          id
          typeHandle
          idName
          backgroundFrames {
            url @transform(mode: "crop", width: 1440, height: 900, immediately: true)
            title
          }
        }
        ... on masthead_block_BlockType {
          id
          typeHandle
          idName
          backgroundImage {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          backgroundImageMobile {
            url @transform(mode: "crop", width: 640, immediately: true)
            title
          }
          backgroundVideo {
            url
            title
            embeddedAsset {
              title
              url
              providerName
            }
          }
          backgroundVideoPoster {
            url @transform(mode: "crop", width: 2000, immediately: true)
            title
          }
          typographyVariant
          heading
          copy
          additionalName
          additionalOccupation
          button {
            type
            url
            text
            target
          }
          buttonVariant
          scrollButton
          scrollButtonStyle
        }
      }
    `
  },
  async mounted () {
    if (this.content) {
      this.blocksActive = Array(this.$refs.blockNormal.length).fill(false)
      this.blocksOpacity = Array(this.$refs.blockNormal.length).fill(1, 0, 1).fill(0, 1)

      window.addEventListener('scroll', this.handleWindowScroll)
      this.handleWindowScroll()

      if (this.content.backgroundFrames && this.content.backgroundFrames.length) {
        await this.setBackgroundFrames()
        this.backgroundCanvasContext = this.$refs.backgroundCanvas.getContext('2d')
        gsap.registerPlugin(ScrollTrigger)
        this.renderBackgroundContext()
        this.$nextTick(() => {
          this.renderBackgroundContext()
          setTimeout(() => {
            this.renderBackgroundContext()
          }, 500)
        })
        gsap.fromTo(this.backgroundAnimation, {
          frame: 0
        }, {
          frame: 0,
          snap: 'frame',
          onUpdate: this.renderBackgroundContext,
          onComplete: this.setScrollTrigger
        })
      }
    }

    if (this.field[0].backgroundVideo[0]?.url.length) {
      const mastheadVideo = document.getElementById('mastheadBackgroundVideo')

      if (this.field[0].backgroundVideo[0]?.embeddedAsset?.url.length) {
        if (Hls.isSupported()) {
          const hls = new Hls()

          hls.attachMedia(mastheadVideo)
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(
              this.field[0].backgroundVideo[0].embeddedAsset.url
            )
          })
        } else if (mastheadVideo.canPlayType('application/vnd.apple.mpegurl')) {
          mastheadVideo.src = this.field[0].backgroundVideo[0]?.embeddedAsset?.url
        }
      } else {
        mastheadVideo.src = this.field[0].backgroundVideo[0]?.url
      }
    }
  },
  beforeDestroy () {
    if (this.content) {
      window.removeEventListener('scroll', this.handleWindowScroll)
      if (this.content.backgroundFrames && this.content.backgroundFrames.length) {
        ScrollTrigger.getAll().forEach(t => t.kill())
      }
    }
    this.$store.commit('cMasthead/setIsIntersecting', false)
  },
  methods: {
    checkWebPSupport () {
      return new Promise((resolve) => {
        const img = new Image()
        img.onload = function () { resolve(true) }
        img.onerror = function () { resolve(false) }
        img.src = 'http://www.gstatic.com/webp/gallery/1.webp'
      })
    },
    handleWindowScroll () {
      const isIntersecting = (this.$el.getBoundingClientRect().top <= window.innerHeight * 1.25) && (this.$el.getBoundingClientRect().bottom >= window.innerHeight * 1.25)
      if (this.$store.state.cMasthead.isIntersecting !== isIntersecting) {
        this.$store.commit('cMasthead/setIsIntersecting', isIntersecting)
      }
      this.$refs.blockNormal.forEach((element, index) => {
        this.$set(
          this.blocksActive,
          index,
          (element.getBoundingClientRect().top <= window.innerHeight * 0.5) && (element.getBoundingClientRect().bottom >= window.innerHeight * 0.5)
        )
        if (index > 0) {
          let blockOpacity = element.getBoundingClientRect().top / window.innerHeight * -1 + 1
          blockOpacity = blockOpacity * (Math.abs(blockOpacity) + 0.5) // Make images stay solid for longer
          blockOpacity = Math.max(Math.min(blockOpacity, 1), 0)
          this.$set(
            this.blocksOpacity,
            index,
            blockOpacity
          )
        }
      })
      this.isActive = this.blocksActive.includes(true)
    },
    handleScrollButtonClick (blockIndex) {
      window.scrollTo({
        top: this.$refs.blockNormal[blockIndex].getBoundingClientRect().bottom + window.pageYOffset,
        behavior: 'smooth'
      })
    },
    async setBackgroundFrames () {
      const webpSupport = await this.checkWebPSupport()

      this.backgroundFrames = this.content.backgroundFrames.map((backgroundFrame) => {
        const backgroundFrameImage = new Image()
        backgroundFrameImage.src = `${backgroundFrame.url}.${webpSupport ? 'webp' : 'jpg'}`
        backgroundFrameImage.alt = backgroundFrame.title
        return backgroundFrameImage
      })
    },
    setScrollTrigger () {
      // set scroll trigger to animate frames
      this.timeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top top',
          end: 'bottom bottom',
          scrub: 1
        }
      })
        .fromTo(this.backgroundAnimation, {
          frame: 0
        }, {
          frame: this.backgroundFrames.length - 1,
          snap: 'frame',
          onUpdate: this.renderBackgroundContext
        })
    },
    renderBackgroundContext () {
      // draw current frame on canvas
      this.backgroundCanvasContext.clearRect(0, 0, 1600, 900)
      this.backgroundCanvasContext.drawImage(this.backgroundFrames[this.backgroundAnimation.frame], 0, 0)
    }
  }
}
