
export default {
  props: {
    field: { type: Array, default: null },
    margins: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      formSearchValue: '',
      checked: false,
      inputStatusLabel: null,
      inputIsError: false,
      checkedIsError: false,
      checkStatusLabel: null,
      isLoading: false
    }
  },
  computed: {
    content () {
      return this.field && this.field.length ? this.field[0] : null
    }
  },
  methods: {
    handleCheck (value) {
      this.checked = value
    },
    submitSubscribeForNewsletter () {
      if (this.isLoading) {
        return
      }

      const actionUrl = 'https://stibee.com/api/v1.0/lists/j9Fe6vJxBnof-H9hblDJYri_gXBC/public/subscribers'

      this.inputStatusLabel = null
      this.inputIsError = false

      this.checkedStatusLabel = null
      this.checkedIsError = false

      if (!this.checked) {
        this.checkStatusLabel = 'kr-newsletter.submit-no-check-error'
        this.checkedIsError = true
      } else if (this.formSearchValue.length === 0) {
        this.inputStatusLabel = 'kr-newsletter.submit-no-email-error'
        this.inputIsError = true
      } else if (!this.validateEmail(this.formSearchValue)) {
        this.inputStatusLabel = 'kr-newsletter.submit-error'
        this.inputIsError = true
      } else {
        this.isLoading = true
        fetch(actionUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `email=${this.formSearchValue}`
        })
          .then((response) => {
            return response.text()
          })
          .then((data) => {
            /*
              Stibee API call responds with HTML that contains the error message at the top of the
              page commented out. So Stibee's js script splits it out and just returns it as the
              error message.

              This implementation aims to recreate that, main difference being that I have just
              copied the success message from the API and return that rather than implement the
              extra logic they go through.
            */
            const errorMessage = data.split('ERROR_MSG:')

            if (errorMessage.length > 1) {
              this.inputStatusLabel = errorMessage[1].split('-->')[0]
              this.inputIsError = true
            } else {
              this.inputStatusLabel = 'kr-newsletter.submit-success'
              this.inputIsError = false
            }
          })
          .catch((error) => {
            console.log('error', error)
            this.inputStatusLabel = 'kr-newsletter.submit-error'
            this.inputIsError = true
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    validateEmail (email) {
      // regex used in type=”email” from W3C:
      const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      return re.test(String(email).toLowerCase())
    }
  }
}
